import IShipmentProcessingManager from "./interfaces/IShipmentProcessingManager";
import GraphQLAPI, { GRAPHQL_AUTH_MODE, GraphQLResult } from "@aws-amplify/api-graphql";
import "./ShipmentProcessingManager.css";
import * as queries from "../../graphql/queries";
import * as graphQlTypes from "../../graphql/GraphQlTypes";
import * as mutations from "../../graphql/mutations";
import { faCartFlatbed } from "@fortawesome/free-solid-svg-icons";
import PreparedShipmentsBucket from "./components/PreparedShipmentsBucket";
import Spinner from "react-bootstrap/Spinner";
import IShipmentInfo from "./interfaces/IShipmentInfo";
import ProductPackingControl from "./components/ProductPackingControl";
import { useWarehouseContext } from "../../contexts/WarehouseContext";
import { useRef, useState, useEffect } from "react";
import { ConvertToProductInfos } from "../../helpers/EventHelper";
import { SetTimeout } from "../../helpers/DisplayHelper";

export default function ShipmentProcessingManager(props: IShipmentProcessingManager): JSX.Element {
  const warehouseContext = useWarehouseContext()!;
  const selectedShipment = useRef<IShipmentInfo>();
  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  const [autoProcessingEnabled, setAutoProcessingEnabled] = useState<boolean>(false);
  // const autoProcessingEnabled = useRef<boolean>(false);
  const isShipmentProcessingInProgress = useRef<boolean>(false);

  useInterval(() => {
    // Your custom logic here
    scheduleAutoProcessing(warehouseContext.preparedShipments);
  }, 3000);

  function useInterval(callback: any, delay: number) {
    const savedCallback = useRef<any>();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const scheduleAutoProcessing = async (preparedShipments: IShipmentInfo[]) => {
    // console.log("Auto-processing triggered");

    // console.log(autoProcessingEnabled.current);
    // console.log(isShipmentProcessingInProgress.current);
    if (autoProcessingEnabled && !isShipmentProcessingInProgress.current) {
      // console.error("Started processing");
      await onProcessShipment(preparedShipments);
    }

    // await SetTimeout(5000);
    // await scheduleAutoProcessing();
  };

  const onShipmentSelected = async (shipment: IShipmentInfo) => {
    const fetchData = async () => {
      if (selectedShipment.current !== undefined) {
        if (selectedShipment.current.shipmentId === shipment.shipmentId) {
          return;
        }
        warehouseContext.preparedShipments.forEach(function (shipment: IShipmentInfo) {
          shipment.isSelected = false;
        });
        // selectedShipment.current.isSelected = false;
      }
      shipment.isSelected = true;
      selectedShipment.current = shipment;
      try {
        setProductsLoading(true);
        const getPicklistsQueryVariables = {
          userId: shipment.userId,
          metadata: "picklist#" + shipment.shipmentId + "#",
        } as graphQlTypes.GetPicklistsQueryVariables;
        const result = await (GraphQLAPI.graphql({
          query: queries.getPicklists,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          variables: getPicklistsQueryVariables,
        }) as Promise<GraphQLResult<graphQlTypes.GetPicklistsQuery>>);

        if (result.data && result.data?.getPicklists.items.length) {
          warehouseContext.setPackedProducts([]);
          if (result.data !== undefined) {
            var picklists = ConvertToProductInfos(result.data.getPicklists.items);
            warehouseContext.setPendingProducts(picklists);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setProductsLoading(false);
      }
    };

    await fetchData();
  };

  const onClearDataHandler = async () => {
    //selectedShipment.current = undefined;
    //await loadData();
    // if (shipmentStatusContext.preparedShipments.length > 0) {
    //   await onShipmentSelected(shipmentStatusContext.preparedShipments[0]);
    //   //shipmentStatusContext.preparedShipments[0].isSelected = true;
    // }
  };

  const onProcessShipment = async (preparedShipments: IShipmentInfo[]) => {
    try {
      isShipmentProcessingInProgress.current = true;
      console.log("Started shipment processing");
      if (preparedShipments.length === 0) {
        console.log("No shipments to process");
        return;
      }
      const oldestShipment = preparedShipments.slice(-1)[0];
      // console.log(oldestShipment);

      if (selectedShipment.current !== undefined) {
        preparedShipments.forEach(function (shipment: IShipmentInfo) {
          shipment.isSelected = false;
        });
      }

      oldestShipment.isSelected = true;
      warehouseContext.setPendingProducts([]);

      await SetTimeout(500);

      const getPicklistsQueryVariables = {
        userId: oldestShipment.userId,
        metadata: "picklist#" + oldestShipment.shipmentId + "#",
      } as graphQlTypes.GetPicklistsQueryVariables;
      const shipmentResult = await (GraphQLAPI.graphql({
        query: queries.getPicklists,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        variables: getPicklistsQueryVariables,
      }) as Promise<GraphQLResult<graphQlTypes.GetPicklistsQuery>>);

      if (shipmentResult.data === undefined) {
        return;
      }

      if (shipmentResult.data && shipmentResult.data.getPicklists.items.length) {
        warehouseContext.setPackedProducts([]);
        var picklists = ConvertToProductInfos(shipmentResult.data.getPicklists.items);
        warehouseContext.setPendingProducts(picklists);

        await SetTimeout(500);

        console.log(picklists);

        warehouseContext.setPackedProducts(picklists);
        warehouseContext.setPendingProducts([]);

        await SetTimeout(500);

        await processShipment(oldestShipment);
      }
    } catch (error) {
      console.error(error);
    } finally {
      isShipmentProcessingInProgress.current = false;
    }
  };

  const processShipment = async (shipment: IShipmentInfo) => {
    try {
      const completeShipmentMutationVariables = {
        userId: shipment.userId,
        orderId: shipment.orderId,
        storeId: shipment.storeId,
        shipmentId: shipment.shipmentId,
      } as graphQlTypes.CompleteShipmentMutationVariables;

      const productResult = await (GraphQLAPI.graphql({
        query: mutations.completeShipment,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        variables: completeShipmentMutationVariables,
      }) as Promise<GraphQLResult<graphQlTypes.CompleteShipmentMutation>>);

      if (!productResult.errors) {
        if (!autoProcessingEnabled) {
          warehouseContext.removePreparedShipment(shipment.shipmentId);
        }
        warehouseContext.setPackedProducts([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onAutoProcessing = async (status: boolean) => {
    try {
      // console.log("Started shipment processing");
      // console.log(status);
      setAutoProcessingEnabled(status);
      // autoProcessingEnabled.current = status;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="shipments-dashboard-main">
      {/* <button className="sign-out-button" type="submit" onClick={() => onProcessShipment(warehouseContext.preparedShipments)}>
        Process Shipment
      </button> */}
      {warehouseContext.preparedShipments.length > 0 ? (
        <div className="shipments-dashboard-container">
          <section className="shipments-dashboard-section">
            <PreparedShipmentsBucket
              bucketTitle="Shipments To Process:"
              bucketIcon={faCartFlatbed}
              shipments={warehouseContext.preparedShipments}
              onShipmentSelected={onShipmentSelected}
              autoProcessingEnabled={autoProcessingEnabled}
              onAutoProcessing={onAutoProcessing}
            />
            <ProductPackingControl selectedShipment={selectedShipment.current} productsLoading={productsLoading} onClearData={onClearDataHandler} />
          </section>
        </div>
      ) : warehouseContext.noPreparedShipments ? (
        <div className="shipments-dashboard-container-empty">
          <section className="shipments-dashboard-empty-section">
            <span className="shipments-dashboard-empty-label">You do not have any Shipments to process.</span>
          </section>
        </div>
      ) : (
        <div className="shipments-dashboard-container-empty">
          <Spinner className="warehouse-spinner" animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
