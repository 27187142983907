import uniqolor from "uniqolor";
import IProductCard from "../interfaces/IProductCard";
import "./ProductCard.css";

export default function ProductCard({ product }: IProductCard): JSX.Element {
  return (
    <div className="product-card-pane">
      <div
        className="product-card-container product-card-link-hover"
        style={{
          backgroundColor: uniqolor(product.productId, {
            saturation: [35, 70],
            lightness: [75, 90],
            differencePoint: 50,
          }).color,
        }}
      >
        <div className="product-card-vertical-segment">
          <span className="product-card-attribute-label">Product Id:</span>
          <span className="product-card-id-label">{product.productId.substring(0, 18)}</span>
        </div>
        <div className="product-card-horizontal-delimiter" />
        <div
          className="product-card-vertical-segment"
          style={{
            backgroundColor: uniqolor(product.shipmentId, {
              saturation: [35, 70],
              lightness: [75, 90],
              differencePoint: 50,
            }).color,
          }}
        >
          <span className="product-card-attribute-label">Shipment Id:</span>
          <span className="product-card-id-label product-card-padding-bottom">{product.shipmentId.substring(0, 18)}</span>
        </div>
      </div>
    </div>
  );
}
