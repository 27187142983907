import { motion } from "framer-motion";
import uniqolor from "uniqolor";
import { GetShipmentEventAnimation } from "../../../helpers/AnimationHelper";
import IPreparedShipmentCard from "../interfaces/IPreparedShipmentCard";
import "./PreparedShipmentCard.css";

export default function PreparedShipmentCard({ shipment, onShipmentSelected }: IPreparedShipmentCard): JSX.Element {
  return (
    <motion.div
      className="prepared-shipment-pane"
      layout
      key={shipment.shipmentId}
      variants={GetShipmentEventAnimation()}
      initial="initial"
      animate="animate"
      exit="exit"
      whileHover={{
        scale: 1.01,
      }}
      whileTap={{ scale: 0.99 }}
      onClick={() => onShipmentSelected(shipment)}
    >
      <div
        className={shipment.isSelected ? "prepared-shipment-container-selected" : "prepared-shipment-container"}
        style={{
          backgroundColor: uniqolor(shipment.shipmentId, {
            saturation: [35, 70],
            lightness: [75, 90],
            differencePoint: 50,
          }).color,
        }}
      >
        <div className="prepared-shipment-vertical-segment">
          <span className="prepared-shipment-attribute-label">Shipment Id:</span>
          <span className="prepared-shipment-id-label">{shipment.shipmentId.substring(0, 18)}</span>
        </div>
        <div className="prepared-shipment-horizontal-delimiter" />
        <div className="prepared-shipment-vertical-segment">
          <span className="prepared-shipment-attribute-label">Shipment Creation Date:</span>
          <span className="prepared-shipment-date-label prepared-shipment-padding-bottom">
            {new Intl.DateTimeFormat("us-US", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(shipment.shipmentCreationDate))}
          </span>
        </div>
      </div>
    </motion.div>
  );
}
