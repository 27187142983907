import { Variants } from "framer-motion";

const shipmentEventAnimation: Variants = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
  },
};

export const GetShipmentEventAnimation = () => {
  return shipmentEventAnimation;
};
