import { useState, useEffect } from "react";
import IProfileManager from "./interfaces/IProfileManager";
import Table from "react-bootstrap/Table";
import { Auth } from "aws-amplify";
import "./ProfileManager.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import IUserInfo from "./interfaces/IUserInfo";
import { Spinner } from "react-bootstrap";
import { useWarehouseContext } from "../../contexts/WarehouseContext";
import { EventMode } from "../../contexts/WarehouseContextProvider";

export default function ProfileManager(props: IProfileManager): JSX.Element {
  const warehouseContext = useWarehouseContext()!;
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [signOutInProgress, setSignOutInProgress] = useState(false);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        // console.log("attributes:", user.attributes);
        setUserInfo({
          email: user.attributes.email,
          emailVerified: user.attributes.email_verified,
          firstName: user.attributes.given_name,
          lastName: user.attributes.family_name,
        });
      } catch (error) {
        console.error(error);
      }
    }

    getUserInfo();
    // console.log(userInfo);
  }, []);

  const getVerificationIcon = (verificationStatus: boolean | undefined) => {
    if (verificationStatus) {
      return faSquareCheck;
    } else {
      return faBan;
    }
  };

  const onSignOut = async () => {
    try {
      setSignOutInProgress(true);
      await Auth.signOut();
      setSignOutInProgress(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSignOutInProgress(false);
    }
  };

  return (
    <div className="profile-container">
      <section className="profile-section">
        <span className="profile-label">Your Profile:</span>
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td className="profile-table-bold">First Name</td>
              <td>{userInfo?.firstName}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Last Name</td>
              <td>{userInfo?.lastName}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Email</td>
              <td>{userInfo?.email}</td>
            </tr>
            <tr>
              <td className="profile-table-bold">Email Verified</td>
              <td className="profile-table-column-center">
                <FontAwesomeIcon icon={getVerificationIcon(userInfo?.emailVerified)} color="orange" />
              </td>
            </tr>
            <tr>
              <td className="profile-table-bold">StoreId</td>
              <td>
                {warehouseContext.storeId !== "" ? (
                  <span>{warehouseContext.storeId}</span>
                ) : (
                  <span className="profile-value-empty-span">StoreId is not set</span>
                )}
              </td>
            </tr>
            <tr>
              <td className="profile-table-bold">Event Mode</td>
              <td>
                {warehouseContext.eventMode !== EventMode.NONE ? (
                  <span>{warehouseContext.eventMode}</span>
                ) : (
                  <span className="profile-value-empty-span">Event Mode is not set</span>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="sign-out-component">
          <button className="sign-out-button" type="submit" disabled={signOutInProgress} onClick={onSignOut}>
            Sign Out
            {signOutInProgress ? (
              <Spinner className="sign-out-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              <span />
            )}
          </button>
        </div>
      </section>
    </div>
  );
}
