/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const completeShipment = /* GraphQL */ `
  mutation CompleteShipment(
    $orderId: String!
    $shipmentId: String!
    $storeId: String
    $userId: ID!
  ) {
    completeShipment(
      orderId: $orderId
      shipmentId: $shipmentId
      storeId: $storeId
      userId: $userId
    )
  }
`;
export const publishShipmentStatusUpdate = /* GraphQL */ `
  mutation PublishShipmentStatusUpdate(
    $shipmentStatusUpdate: PublishShipmentStatusUpdateInput!
  ) {
    publishShipmentStatusUpdate(shipmentStatusUpdate: $shipmentStatusUpdate) {
      metadata
      orderId
      shipmentCreationDate
      shipmentId
      shipmentStatus
      storeId
      userId
    }
  }
`;
export const publishUserProfileUpdate = /* GraphQL */ `
  mutation PublishUserProfileUpdate(
    $userProfileUpdate: PublishUserProfileUpdateInput!
  ) {
    publishUserProfileUpdate(userProfileUpdate: $userProfileUpdate) {
      demoEventMode
      storeId
      userId
    }
  }
`;
