import { ReactSortable } from "react-sortablejs";
import IProductBucket from "../interfaces/IProductBucket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCard from "./ProductCard";
import "./ProductBucket.css";

export default function ProductBucket({ products, setProducts, bucketTitle, bucketIcon, productsLoading, bucketStyle }: IProductBucket): JSX.Element {
  return (
    <div className="products-bucket-segment" style={bucketStyle}>
      <div className="products-bucket-title-container">
        <FontAwesomeIcon className="products-bucket-title-icon" icon={bucketIcon} />
        <span className="products-bucket-title-label">{bucketTitle}</span>
      </div>
      <ReactSortable
        className="products-bucket-container"
        list={products}
        setList={setProducts}
        group="product-packing-group"
        animation={300}
        delay={5}
        sort={false}
        style={{ opacity: productsLoading ? "50%" : "100%" }}
      >
        {products.map((product) => {
          return <ProductCard key={product.productId} product={product} />;
        })}
      </ReactSortable>
    </div>
  );
}
