import { Routes, Route } from "react-router-dom";
import NavigationBar from "./portal/navigation/NavigationBar";
import Amplify from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import ProfileManager from "./portal/profile/ProfileManager";
import ShipmentProcessingManager from "./portal/shipment-processing/ShipmentProcessingManager";
import AboutManager from "./portal/about/AboutManager";
import { GetAmplifyConfigurationFromEnvironmentFile, GetPortalConfigurationFromEnvironmentFile } from "./helpers/ConfigurationHelper";

Amplify.configure(GetAmplifyConfigurationFromEnvironmentFile());

const portalConfiguration = GetPortalConfigurationFromEnvironmentFile();

document.title = portalConfiguration.PortalTitle;

const formFields = {
  signUp: {
    given_name: {
      placeholder: "First Name",
      order: 1,
    },
    family_name: {
      placeholder: "Last Name",
      order: 2,
    },
    email: {
      order: 3,
    },
    password: {
      order: 4,
    },
    confirm_password: {
      order: 5,
    },
  },
};

function App(): JSX.Element {
  return (
    <div className="admin-dashboard-container">
      <NavigationBar portalName={portalConfiguration.PortalTitle} />
      <Authenticator className="data-amplify-authenticator" formFields={formFields} hideSignUp={true}>
        {() => (
          <Routes>
            <Route path="/" element={<ShipmentProcessingManager />} />
            <Route path="about" element={<AboutManager />} />
            <Route path="profile" element={<ProfileManager />} />
          </Routes>
        )}
      </Authenticator>
    </div>
  );
}

export default App;
