import React, { useCallback, useEffect, useRef, useState } from "react";
import { WarehouseContext } from "./WarehouseContext";
import IProductInfo from "../portal/shipment-processing/interfaces/IProductInfo";
import IShipmentInfo from "../portal/shipment-processing/interfaces/IShipmentInfo";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import * as graphQlTypes from "../graphql/GraphQlTypes";
import GraphQLAPI, { GRAPHQL_AUTH_MODE, GraphQLResult } from "@aws-amplify/api-graphql";
import { ConvertToShipmentInfo, ConvertToShipmentInfos } from "../helpers/EventHelper";
import { ZenObservable } from "zen-observable-ts";
import { GraphQLSubscription } from "@aws-amplify/api";
import { API, Auth, graphqlOperation, Hub } from "aws-amplify";

type Props = {
  children: React.ReactNode;
};

export enum EventMode {
  NONE = "None",
  STORE_MODE = "Store Mode",
  ALL_EVENTS = "All Events",
}

export const WarehouseContextProvider = ({ children }: Props) => {
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [eventMode, setEventMode] = useState<EventMode>(EventMode.NONE);
  const [preparedShipments, setPreparedShipments] = useState<IShipmentInfo[]>([]);
  const [noPreparedShipments, setNoPreparedShipments] = useState<boolean>(true);
  const [pendingProducts, setPendingProducts] = useState<IProductInfo[]>([]);
  const [packedProducts, setPackedProducts] = useState<IProductInfo[]>([]);
  const [shipmentEventNotification, setShipmentEventNotification] = useState<graphQlTypes.ShipmentStatusUpdate>();
  const eventConnections = useRef<Map<EventMode, ZenObservable.Subscription>>(new Map<EventMode, ZenObservable.Subscription>());
  var userProfileConnection = useRef<ZenObservable.Subscription>();
  const userSignedUp = useRef<boolean>(false);

  useEffect(() => {
    async function initializeContext() {
      try {
        // console.info("Store Context Initialized");
        console.log("Context initializing");

        Hub.remove("auth", listener);
        Hub.listen("auth", listener);

        await applyUserName();

        await loadUserProfile();
      } catch (error) {
        console.error(error);
      }
    }

    initializeContext();

    return () => Hub.remove("auth", listener);
  }, []);

  useEffect(() => {
    const processEventModeChange = async () => {
      if (noPreparedShipments) {
        console.log("All shipments processed, fetching new records");
        try {
          if (eventMode !== EventMode.NONE) {
            await loadData();
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    processEventModeChange();
  }, [noPreparedShipments]);

  const applyUserName = async () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.currentUserInfo().then((info) => {
          setUserId(info.attributes.sub);
          setUserName(info.attributes.given_name + " " + info.attributes.family_name);
        });
      })
      .catch((err) => {
        setUserId("");
        setUserName("");
      });
  };

  const loadUserProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user.attributes.sub) {
        const getUserProfileQueryVariables = {
          userId: user.attributes.sub,
        } as graphQlTypes.GetUserProfileQueryVariables;
        const result = await (GraphQLAPI.graphql({
          query: queries.getUserProfile,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          variables: getUserProfileQueryVariables,
        }) as Promise<GraphQLResult<graphQlTypes.GetUserProfileQuery>>);

        //console.log(result.data);

        if (result.data && result.data.getUserProfile) {
          setStoreId(result.data.getUserProfile.storeId ?? "");
          setEventMode((result.data.getUserProfile.demoEventMode as EventMode) ?? EventMode.NONE);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Connection logic

  useEffect(() => {
    const processEventModeChange = async () => {
      try {
        await establishAppSyncConnectionForUser();
        await loadData();
      } catch (error) {
        console.error(error);
      }
    };

    processEventModeChange();
  }, [eventMode]);

  const establishAppSyncConnectionForUser = () => {
    if (eventMode === EventMode.NONE) {
      clearEventConnections();
      return;
    }

    if (userId === "") return;

    var connection = eventConnections.current.get(eventMode);

    if (!connection || connection.closed) {
      console.log(eventMode + " activated");
      establishAppSyncShipmentConnection(0);
    } else {
      console.log("Connection for Event Mode '" + eventMode + "' has already established.");
    }
  };

  useEffect(() => {
    if (userId === "") return;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //establishConnection(0);
        establishAppSyncConnectionForUser();
        establishAppSyncUserProfileConnection(0);
      })
      .catch((err) => {
        //console.error(err);
      });
  }, [userId]);

  useEffect(() => {
    const existingConnection = eventConnections.current.get(EventMode.STORE_MODE);
    if (existingConnection) {
      if (!existingConnection.closed) {
        console.log("Unsubscribing from existing " + EventMode.STORE_MODE + " connection");
        existingConnection.unsubscribe();
      }
    }
  }, [storeId]);

  const subscribeToAppSync = async () => {
    clearEventConnections();

    if (eventMode === EventMode.STORE_MODE) {
      await subscribeToStoreUpdates();
    }
  };

  async function establishAppSyncUserProfileConnection(delay: number) {
    await timeout(delay);
    subscribeToUserProfileUpdates();
  }

  const subscribeToUserProfileUpdates = async () => {
    try {
      clearProfileConnections();

      const user = await Auth.currentAuthenticatedUser();
      if (user.attributes.sub) {
        console.info("Subscribing to AppSync profile updates for user " + user.attributes.sub);

        const session = await Auth.currentSession();
        const idToken = await session.getIdToken();
        const token = await idToken.getJwtToken();

        userProfileConnection.current = API.graphql<GraphQLSubscription<typeof subscriptions.onUserProfileUpdate>>(
          graphqlOperation(
            subscriptions.onUserProfileUpdate,
            {
              userId: user.attributes.sub,
            },
            token
          )
        ).subscribe({
          next: ({ provider, value }) => onUserProfileUpdateEvent(value),
          error: (error: string) => onAppSyncUserProfileSubscriptionError(error),
        });

        console.info("Subscribed to AppSync profile updates for user " + user.attributes.sub);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const onUserProfileUpdateEvent = useCallback(
    (value) => {
      //console.info(value);
      var event = value.data.onUserProfileUpdate as graphQlTypes.UserProfileUpdate;
      //console.info(event);
      if (event) {
        console.log(event);
        //setShipmentEventNotification(event);
        setStoreId(event.storeId ?? "");
        setEventMode(event.demoEventMode as EventMode);
      }
    },
    [preparedShipments]
  );

  const onAppSyncUserProfileSubscriptionError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }

    establishAppSyncUserProfileConnection(5000);
  };

  const subscribeToStoreUpdates = async () => {
    try {
      if (storeId) {
        console.info("Subscribing to AppSync shipment updates for store " + storeId);

        const session = await Auth.currentSession();
        const idToken = await session.getIdToken();
        const token = await idToken.getJwtToken();

        const storeShipmentsSubscription = API.graphql<GraphQLSubscription<typeof subscriptions.onStoreShipmentStatusUpdate>>(
          graphqlOperation(
            subscriptions.onStoreShipmentStatusUpdate,
            {
              storeId: storeId,
            },
            token
          )
        ).subscribe({
          next: ({ provider, value }) => onStoreShipmentStatusEvent(value),
          error: (error: string) => onAppSyncShipmentSubscriptionError(error),
        });

        eventConnections.current.set(EventMode.STORE_MODE, storeShipmentsSubscription);

        console.info("Subscribed to AppSync shipment updates for store " + storeId);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const onAppSyncShipmentSubscriptionError = (error: string) => {
    console.error(error);
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }

    establishAppSyncShipmentConnection(5000);
  };

  async function establishAppSyncShipmentConnection(delay: number) {
    await timeout(delay);
    subscribeToAppSync();
  }

  const addPreparedShipment = useCallback(
    async (item: IShipmentInfo) => {
      try {
        //console.log(item);
        var preparedShipment = preparedShipments.find((p) => p.shipmentId === item.shipmentId);
        if (preparedShipment) {
          return;
        }

        var shipmentsSorted = [...preparedShipments, item].sort(function (a: IShipmentInfo, b: IShipmentInfo) {
          if (a.shipmentCreationDate < b.shipmentCreationDate) return 1;
          if (a.shipmentCreationDate > b.shipmentCreationDate) return -1;
          return 0;
        });
        setPreparedShipments(shipmentsSorted);
      } catch (error) {
        console.error(error);
      }
    },
    [preparedShipments]
  );

  const removePreparedShipment = async (shipmentId: string) => {
    var items = preparedShipments.filter((item) => item.shipmentId !== shipmentId);
    // console.log(items);
    if (items.length === 0) {
      setNoPreparedShipments(true);
    }
    if (pendingProducts.length > 0) {
      if (pendingProducts[0].shipmentId === shipmentId) {
        setPendingProducts([]);
      }
    }
    if (packedProducts.length > 0) {
      if (packedProducts[0].shipmentId === shipmentId) {
        setPackedProducts([]);
      }
    }
    setPreparedShipments(items);
  };

  const listener = (data: any) => {
    console.info(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        applyUserName();
        loadUserProfile();
        break;

      case "signOut":
        applyUserName();
        clearEventConnections();
        clearProfileConnections();
        setEventMode(EventMode.NONE);
        break;

      case "signUp":
        userSignedUp.current = true;
        break;
    }
  };

  const loadData = async () => {
    if (eventMode === EventMode.STORE_MODE) {
      await loadStoreShipment();
    }
  };

  const loadStoreShipment = async () => {
    try {
      if (storeId) {
        const getPreparedShipmentsQueryVariables = {
          storeId: storeId,
        } as graphQlTypes.GetStorePreparedShipmentsQueryVariables;
        const result = await (GraphQLAPI.graphql({
          query: queries.getStorePreparedShipments,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          variables: getPreparedShipmentsQueryVariables,
        }) as Promise<GraphQLResult<graphQlTypes.GetStorePreparedShipmentsQuery>>);

        // console.log(result.data);

        if (result.data && result.data.getStorePreparedShipments.items.length > 0) {
          console.log(result.data.getStorePreparedShipments.items.length);
          var shipments = ConvertToShipmentInfos(result.data?.getStorePreparedShipments.items);

          var shipmentsSorted = shipments.sort(function (a: IShipmentInfo, b: IShipmentInfo) {
            if (a.shipmentCreationDate < b.shipmentCreationDate) return 1;
            if (a.shipmentCreationDate > b.shipmentCreationDate) return -1;
            return 0;
          });

          setPreparedShipments(shipmentsSorted);
          setNoPreparedShipments(false);
        } else {
          setPreparedShipments([]);
          setNoPreparedShipments(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function processEvent() {
      if (shipmentEventNotification) {
        // console.info(shipmentEventNotification);
        // console.info(shipmentEventNotification.shipmentStatus);
        // console.info(shipmentEventNotification.shipmentStatus === "ShipmentProcessed");
        if (shipmentEventNotification.shipmentStatus === "PROCESSED") {
          removePreparedShipment(shipmentEventNotification.shipmentId);
        } else if (shipmentEventNotification.shipmentStatus === "PREPARED") {
          const shipmentInfo = ConvertToShipmentInfo(shipmentEventNotification);
          if (shipmentInfo) {
            await addPreparedShipment(shipmentInfo);
          }
        } else {
          throw new Error("Shipment status is not supported" + shipmentEventNotification.shipmentStatus);
        }
      }
    }

    processEvent();
  }, [shipmentEventNotification]);

  // const onUserShipmentStatusEvent = useCallback(
  //   (value) => {
  //     console.info(value);
  //     var event = value.data.onUserShipmentStatusUpdate as graphQlTypes.ShipmentStatusUpdate;
  //     console.info(event);
  //     if (event) {
  //       setShipmentEventNotification(event);
  //     }
  //   },
  //   [preparedShipments]
  // );

  const onStoreShipmentStatusEvent = useCallback(
    (value) => {
      console.info(value);
      var event = value.data.onStoreShipmentStatusUpdate as graphQlTypes.ShipmentStatusUpdate;
      console.info(event);
      if (event) {
        setShipmentEventNotification(event);
      }
    },
    [preparedShipments]
  );

  const clearEventConnections = () => {
    // Unsubscribe from all other WebSockets connections
    Object.values<string>(EventMode)
      .filter((item) => item !== EventMode.NONE)
      .forEach(function (mode) {
        const existingConnection = eventConnections.current.get(mode as EventMode);
        if (existingConnection) {
          //console.log("Existing " + mode + " connection closed?: " + existingConnection.closed);
          if (!existingConnection.closed) {
            console.log("Unsubscribing from existing " + mode + " connection");
            existingConnection.unsubscribe();
            //console.log("Existing " + mode + " connection closed?: " + existingConnection.closed);
          }
        }
      });
  };

  const clearProfileConnections = () => {
    // Unsubscribe from all other WebSockets connections

    if (userProfileConnection.current) {
      //console.log("Existing profile connection closed?: " + userProfileConnection.current.closed);
      if (!userProfileConnection.current.closed) {
        console.log("Unsubscribing from existing profile connection");
        userProfileConnection.current.unsubscribe();
        //console.log("Existing profile connection closed?: " + userProfileConnection.current.closed);
      }
    }
  };

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  return (
    <WarehouseContext.Provider
      value={{
        preparedShipments,
        setPreparedShipments,
        noPreparedShipments,
        pendingProducts,
        setPendingProducts,
        packedProducts,
        setPackedProducts,
        userId,
        userName,
        setUserName,
        storeId,
        eventMode,
        removePreparedShipment,
      }}
    >
      {children}
    </WarehouseContext.Provider>
  );
};
