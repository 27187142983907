/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onStoreShipmentStatusUpdate = /* GraphQL */ `
  subscription OnStoreShipmentStatusUpdate($storeId: ID!) {
    onStoreShipmentStatusUpdate(storeId: $storeId) {
      metadata
      orderId
      shipmentCreationDate
      shipmentId
      shipmentStatus
      storeId
      userId
    }
  }
`;
export const onUserProfileUpdate = /* GraphQL */ `
  subscription OnUserProfileUpdate($userId: ID!) {
    onUserProfileUpdate(userId: $userId) {
      demoEventMode
      storeId
      userId
    }
  }
`;
