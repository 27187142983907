import React from "react";
import IProductInfo from "../portal/shipment-processing/interfaces/IProductInfo";
import IShipmentInfo from "../portal/shipment-processing/interfaces/IShipmentInfo";
import { EventMode } from "./WarehouseContextProvider";

type WarehouseContextType = {
  preparedShipments: IShipmentInfo[];
  setPreparedShipments: (shipments: IShipmentInfo[]) => void;
  noPreparedShipments: boolean;
  pendingProducts: IProductInfo[];
  setPendingProducts: (products: IProductInfo[]) => void;
  packedProducts: IProductInfo[];
  setPackedProducts: (products: IProductInfo[]) => void;
  userId: string;
  userName: string;
  storeId: string;
  eventMode: EventMode;
  setUserName: (value: string) => void;
  removePreparedShipment: (shipmentId: string) => void;
};

export const WarehouseContext = React.createContext<WarehouseContextType | undefined>(undefined);

export const useWarehouseContext = () => React.useContext(WarehouseContext);
