import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { WarehouseContextProvider } from "./contexts/WarehouseContextProvider";

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <WarehouseContextProvider>
        <App />
      </WarehouseContextProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
