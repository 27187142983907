import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PreparedShipmentsBucket.css";
import IPreparedShipmentsBucket from "../interfaces/IPreparedShipmentsBucket";
import PreparedShipmentCard from "./PreparedShipmentCard";
import { Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function PreparedShipmentsBucket({
  bucketTitle,
  bucketIcon,
  shipments,
  onShipmentSelected,
  autoProcessingEnabled,
  onAutoProcessing,
}: IPreparedShipmentsBucket): JSX.Element {
  const onAutoProcessingHandler = async (status: boolean) => {
    // console.log(status);
    onAutoProcessing(status);
  };

  return (
    <div className="prepared-shipments-bucket-segment">
      <div className="prepared-shipments-bucket-title-container">
        <OverlayTrigger overlay={<Tooltip className="notification-tooltip">Enable auto-processing</Tooltip>}>
          <Form.Check
            className="profile-table-checkbox"
            type="switch"
            checked={autoProcessingEnabled}
            onChange={(e) => onAutoProcessingHandler(e.target.checked)}
          />
        </OverlayTrigger>
        <FontAwesomeIcon className="prepared-shipments-bucket-title-icon" icon={bucketIcon} />
        <span className="prepared-shipments-bucket-title-label">{bucketTitle}</span>
      </div>
      <AnimatePresence>
        {shipments.map((shipment) => {
          return <PreparedShipmentCard key={shipment.shipmentId} shipment={shipment} onShipmentSelected={onShipmentSelected} />;
        })}
      </AnimatePresence>
    </div>
  );
}
