import { faBoxOpen, faDolly } from "@fortawesome/free-solid-svg-icons";
import IProductPackingControl from "../interfaces/IProductPackingControl";
import { useWarehouseContext } from "../../../contexts/WarehouseContext";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GraphQLAPI, { GRAPHQL_AUTH_MODE, GraphQLResult } from "@aws-amplify/api-graphql";
import * as mutations from "../../../graphql/mutations";
import * as graphQlTypes from "../../../graphql/GraphQlTypes";
import ProductBucket from "./ProductBucket";
import "./ProductPackingControl.css";

export default function ProductPackingControl({ selectedShipment, productsLoading, onClearData }: IProductPackingControl): JSX.Element {
  const warehouseContext = useWarehouseContext()!;
  const [shipmentCompleted, setShipmentCompleted] = useState(false);
  const [processingInProgress, setProcessingInProgress] = useState(false);

  const eventSwal = withReactContent(Swal);

  const onProcessShipmentHandler = async (event: React.ChangeEvent<any>) => {
    try {
      setProcessingInProgress(true);
      if (selectedShipment) {
        const completeShipmentMutationVariables = {
          userId: selectedShipment.userId,
          orderId: selectedShipment.orderId,
          storeId: selectedShipment.storeId,
          shipmentId: selectedShipment.shipmentId,
        } as graphQlTypes.CompleteShipmentMutationVariables;

        const result = await (GraphQLAPI.graphql({
          query: mutations.completeShipment,
          authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
          variables: completeShipmentMutationVariables,
        }) as Promise<GraphQLResult<graphQlTypes.CompleteShipmentMutation>>);

        if (!result.errors) {
          warehouseContext.setPackedProducts([]);
          warehouseContext.removePreparedShipment(selectedShipment.shipmentId);
          await onClearData();
          await eventSwal.fire({
            target: "#product-packing-pane",
            icon: "success",
            title: "Shipment processed!",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            // toast: true,
          });
        }
        // console.log(result.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProcessingInProgress(false);
    }
  };

  useEffect(() => {
    // console.error("Shipment status changed");

    if (warehouseContext.pendingProducts.length === 0 && warehouseContext.packedProducts.length > 0) {
      setShipmentCompleted(true);
      setProcessingInProgress(false);
    } else {
      setShipmentCompleted(false);
    }
  }, [warehouseContext.pendingProducts]);

  return (
    <div className="product-packing-pane" id="product-packing-pane">
      <div
        className="products-packing-section"
        style={{
          display: warehouseContext.pendingProducts.length > 0 || warehouseContext.packedProducts.length > 0 ? "flex" : "none",
        }}
      >
        <ProductBucket
          products={warehouseContext.pendingProducts}
          setProducts={warehouseContext.setPendingProducts}
          bucketTitle="Products to pack:"
          bucketIcon={faDolly}
          productsLoading={productsLoading}
          bucketStyle={{}}
        />
        {shipmentCompleted ? (
          <div className="product-packing-notification-container">
            <div className="product-packing-notification-message-container">
              <span className="product-packing-notification-label">Are you ready to ship the box?</span>
              <button className="product-packing-button" type="submit" disabled={processingInProgress} onClick={onProcessShipmentHandler}>
                Ship the box
                {processingInProgress ? (
                  <Spinner className="product-packing-spinner" as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  <span />
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="product-packing-notification-container">
            <div className="product-packing-notification-message-container">
              <span className="product-packing-notification-label">Please scan a Product barcode using barcode scanner and drag it into the box</span>
            </div>
          </div>
        )}
        <ProductBucket
          products={warehouseContext.packedProducts}
          setProducts={warehouseContext.setPackedProducts}
          bucketTitle="Shipping Box:"
          bucketIcon={faBoxOpen}
          productsLoading={productsLoading}
          bucketStyle={{
            backgroundColor: shipmentCompleted ? "rgba(0, 255, 0, 0.15)" : "rgba(255, 0, 0, 0.15)",
          }}
        />
      </div>
      {!productsLoading ? (
        <div
          className="product-packing-notification-container-empty"
          style={{
            display: warehouseContext.pendingProducts.length === 0 && warehouseContext.packedProducts.length === 0 ? "flex" : "none",
          }}
        >
          <div className="product-packing-notification-message-container-empty">
            <span className="product-packing-notification-label">Please select a shipment to process</span>
          </div>
        </div>
      ) : (
        <div
          className="product-packing-notification-container-empty"
          style={{
            display: warehouseContext.pendingProducts.length === 0 && warehouseContext.packedProducts.length === 0 ? "flex" : "none",
          }}
        >
          <div className="product-packing-notification-message-container-empty">
            <Spinner className="warehouse-spinner" animation="border" role="status" variant="warning">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
    </div>
  );
}
